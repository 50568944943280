<template>
	<div class="forumSec_page">
		<div class="merryArea_mobile">
			<svg-icon icon-class="announce"></svg-icon>
		</div>
		<swiper></swiper>
		<div class="typeArea">
			<div class="crumb">
				<breadcrumb :refer="refer"></breadcrumb>
			</div>
			<div class="content">
				<div class="left">
					<router-view @change="currentChange" :searchVal="searchVal" :isSearch="isSearch"></router-view>
				</div>
				<div class="right">
					<div class="search_area">
						<div class="search_input">
							<a @click="search"><svg-icon icon-class="search"></svg-icon></a>
							<input type="text" @keyup.enter="search" v-model="searchVal">
						</div>
						<div class="caret_icon" @click="isSlideShow=!isSlideShow">
							<i class="el-icon-caret-top" :class="[isSlideShow?'':'rotate_180']"></i>
							<span>{{isSlideShow?'收起':'展开'}}</span>
						</div>
					</div>
					<ul class="operate_list">
						<li @click="toFir('sign')" :class="[current == 0?'current':'']">
							<svg-icon icon-class="sign"></svg-icon>
							<svg-icon icon-class="sign_a"></svg-icon>
							<p>签到</p>
						</li>
						<li @click="toSec('/Home/ForumSec/Article',1)" :class="[current == 1?'current':'']">
							<svg-icon icon-class="write"></svg-icon>
							<svg-icon icon-class="write_a"></svg-icon>
							<p>写文章</p>
						</li>
						<li @click="toSec('/Home/ForumSec/Draft',2)" :class="[current == 2?'current':'']">
							<svg-icon icon-class="draft"></svg-icon>
							<svg-icon icon-class="draft_a"></svg-icon>
							<p>草稿箱</p>
						</li>
						<li @click="toSec('/Home/ForumSec/Collect',3)" :class="[current == 3?'current':'']">
							<svg-icon icon-class="collect"></svg-icon>
							<svg-icon icon-class="collect_a"></svg-icon>
							<p>收藏夹</p>
						</li>
						<li @click="toSec('/Home/ForumSec/Post',4)" :class="[current == 4?'current':'']">
							<svg-icon icon-class="post"></svg-icon>
							<svg-icon icon-class="post_a"></svg-icon>
							<p>我的发帖</p>
						</li>
						<li @click="toSec('/Home/ForumSec/Message',5)" :class="[current == 5?'current':'']">
							<svg-icon icon-class="message"></svg-icon>
							<svg-icon icon-class="message_a"></svg-icon>
							<p>我的消息</p>
						</li>
					</ul>
					<div class="adver_area" v-if="isAdverShow">
						<i class="el-icon-circle-close" @click="isAdverShow=false"></i>
						<p>活动中心</p>
						<div></div>
						<p>热门活动 敬请期待</p>
					</div>
					<div class="end_btn">
						<a>论坛协议</a>
						<a href="https://www.aveva.com/en/legal/privacy-policy/" target="_blank">隐私政策</a>
						<a>联系我们</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Headers from '@/components/header.vue';
	import Swiper from '@/components/Swiper.vue';
	import Breadcrumb from '@/components/Breadcrumb/index.vue';
	import * as forum from '../../api/forum.js';
	

	export default {
		name: "forumSec",
		components: {
			Headers,
			Swiper,
			Breadcrumb
		},
		watch: {
			screenWidth(val) {
				this.screenWidth = val
				if (this.screenWidth < 768) {
					this.isPc = false
				} else {
					this.isPc = true
				}
			}
		},
		created() {
			this.currentInitial();
			this.getAnnounce();
		},
		data() {
			return {
				isNotice: false,
				isAdverShow: true,
				isSearch: false,
				searchVal: '',
				announce: {
					content: '',
					speed: 35
				},
				current: '',
				isSlideShow: true,
				isPc: true,
				screenWidth: document.body.clientWidth,
				refer: [{
					name: '首页',
					href: '/'
				}, {
					name: '驭剑'
				}],
			};
		},
		methods: {
			currentChange(val,title){
				this.current = val;
				this.refer.pop();
				if(this.refer.length < 2){
					this.refer.push({ name: "驭剑", href:'/Home/Forum' })
				}
				this.refer.push({ name: title})
			},
			toFir(type){
				this.$router.push('/Home/Forum?initialType='+type)
			},
			// !右侧导航切换
			toSec(href,current){
				this.$router.push(href)
				this.current = current
			},
			currentInitial(){
				if(this.$router.currentRoute.name == 'Article'){
					this.current = 1
				}else if(this.$router.currentRoute.name == 'Draft'){
					this.current = 2
				}else if(this.$router.currentRoute.name == 'Collect'){
					this.current = 3
				}else if(this.$router.currentRoute.name == 'Post'){
					this.current = 4
				}else if(this.$router.currentRoute.name == 'Message'){
					this.current = 5
				}
			},
			search(){
				this.isSearch = true
				setTimeout(() => {
					this.isSearch = false
				},100)
			},
			getAnnounce(){
				forum.getAnnounce().then(res => {
					if(res.status == 1){
						this.announce.content = '<i class="iconfont icon-trumpet"></i>&nbsp;&nbsp;' + res.data
						this.announce.content = res.data
						this.isNotice = true
					}
				})
			}
		}
	};
</script>

<style>
	@font-face {
		font-family: "iconfont";
		src: url('data:application/x-font-woff2;charset=utf-8;base64,d09GMgABAAAAAALgAAsAAAAABpAAAAKUAAEAAAAAAAAAAAAAAAAAAAAAAAAAAAAAHEIGVgCCcAqBGIElATYCJAMICwYABCAFhG0HMRvMBcgekqQHVTBPbARB4wSAePj//b7tc98dE0mzpolnlTRIItK8kqBUSoISxLp6/BPX/AD9e6vzCoxeJGZTnrf4dDSczYazD9rAohfmdAjCZhJYTjEeISXI0hMJxcJcX8kpeZ/ncnoTKJD5gXJce/NRL8B4a4B7YZRIXEABcMP08gUOxH0C1fpRwM3OaOUKyfSyQNyQxTNXqBCVZVaoFMob9mbxSJnK9CBt8DD6fnw2FyGSsoJu2bnevnTV3lWbkuJ+6b6LgBAjoMNlKDALkImDxuwWr2A8WHXWZxhwrMbgXXWpFNjFUf88UU7X14JeKH0mmZM/an0CGY5PA2xMehtyQ0N5+a0MGr3cCV9ss8HzLf/Z9taPqYeI+A1dRDs9t+CaK77Lk1Uk1RyW51XlaJH5R9fM6uwtjv3n480f8MR9vvch36rWtmvpcKGWTJstyOl/ycJ/5+ZN6v1uS18tP28eD3fep0bXFU/Tf7HnXywnD06Si39DNRm8/WpxkTkb5qfyNL7gd8kGtmWh0IZ0XSnyJdO55RKqVaMEB5tdpxrb7s0dKgsyhqRCHwqVRsnMzqJMjXmUq7SCajO8wzVaTHhFrsK0DUBotEdS7wkKjc5kZi8o0+6Gco3hRbWjaDmzxnjIkQ3CM8ZLaN7C4MI0BOvgSNy4QNHRziiviPjbSGs5ATzDlQtdNJCW2DC9EiszFqBLpg4ueAw1zYQRmQpecKHPbFVZttv0okLL1F0RGwg6w9Al0HQLDFwwGYSgO4tUPr+ARA7NGerg5JLbEFmTJwc8BjeA7GqNQZxHeWbqiqhihglAFzHpgAtmIY3GBEbNsxToAivoT0hbqljTqTtUW9her//dHqimTSnM1ELFTXJ0C9nlAg==') format('woff2');
	}

	.iconfont {
		font-family: "iconfont" !important;
		font-size: 16px;
		font-style: normal;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}

	.icon-trumpet:before {
		content: "\e60a";
	}
</style>

<style lang="scss" scoped>
	$mobil_base: 750;

	@function vw($true) {
		@return ($true / $mobil_base) * 100vw;
	}
	
	.rotate_180{
		transform: rotate(180deg);
	}

	.forumSec_page {
		.merryArea_mobile {
			display: none;
		}

		.typeArea {
			position: relative;

			.crumb {
				margin: 30px 0;
			}


			.content {
				display: flex;
				justify-content: space-between;

				.left {
					width: 900px;
				}

				.right {
					width: 260px;
					margin-bottom: 100px;

					.search_area {
						width: 100%;
						height: 34px;
						
						.search_input{
							border: 4px solid #6b18a8;
							padding: 0 20px;
							display: flex;
							align-items: center;
							svg {
								width: 15px;
								height: 15px;
								margin-right: 5px;
								cursor: pointer;
							}

							input {
								font-size: 14px;
								line-height: 26px;
								width: 100%;
								border: 0;
							}
						}
						.caret_icon{
							display: none;
						}
						
					}

					.operate_list {
						li {
							width: 100%;
							height: 100px;
							padding: 24px 0 0 0;
							margin: 2px 0 0 0;
							background: #f6f8fa;
							text-align: center;
							cursor: pointer;

							p {
								font-size: 16px;
								color: #545C69;
								margin-top: 8px;
							}
							
							svg:first-of-type{
								display: inline;
							}
							svg:nth-of-type(2){
								display: none;
							}
							

							&:first-of-type {
								svg {
									width: 28px;
									height: 26px;
								}
							}

							&:nth-of-type(2) {
								svg {
									width: 30px;
									height: 26px;
								}
							}

							&:nth-of-type(3) {
								svg {
									width: 25px;
									height: 29px;
								}
							}

							&:nth-of-type(4) {
								svg {
									width: 26px;
									height: 22px;
								}
							}

							&:nth-of-type(5) {
								svg {
									width: 25px;
									height: 29px;
								}
							}

							&:nth-of-type(6) {
								svg {
									width: 26px;
									height: 26px;
								}
							}

							&:hover {
								background: #6B18A8;
								box-shadow: 0 5px 10px rgba(0, 0, 0, .5);

								p {
									color: #fff;
								}

								svg {
									color: white;
								}
								svg:first-of-type{
									display: none;
								}
								svg:nth-of-type(2){
									display: inline;
								}
							}
							&.current{
								background: #6B18A8;
								box-shadow: 0 5px 10px rgba(0, 0, 0, .5);
								
								p {
									color: #fff;
								}
								
								svg {
									color: white;
								}
								svg:first-of-type{
									display: none;
								}
								svg:nth-of-type(2){
									display: inline;
								}
							}
						}
					}

					.adver_area {
						width: 100%;
						height: 260px;
						background: url(../../assets/PC/forum/announce_bac.png) center no-repeat;
						background-size: 100%;
						position: relative;
						padding: 50px 0 0 22px;
						margin: 32px 0 24px 0;

						svg {
							width: 23px;
							height: 23px;
							position: absolute;
							right: 16px;
							top: 16px;
							cursor: pointer;
						}

						i {
							font-size: 24px;
							color: #fff;
							position: absolute;
							right: 16px;
							top: 16px;
							cursor: pointer;
						}

						p:first-of-type {
							font-size: 24px;
							font-weight: 700;
							color: #fff;
						}

						div {
							width: 128px;
							height: 3px;
							margin: 16px 0;
							background-image: linear-gradient(to right, #ffbc00, #f70756);
						}

						p:nth-of-type(2) {
							font-size: 16px;
							color: #fff;
						}
					}
					.end_btn {
						display: flex;
						justify-content: space-between;
					
						a {
							font-size: 16px;
							color: #6B18A8;
							text-decoration: underline;
							cursor: pointer;
						}
					}
				}
			}
		}
	}

	@media (max-width: 750px) {
		.forumSec_page {
			.merryArea_mobile {

				svg {
					width: vw(32);
					margin-right: vw(15);
				}

				span {
					height: 100%;
					overflow: auto;
					font-size: vw(20);
					color: #363D42;
				}
			}

			.typeArea {
				.crumb {
					display: none;
					margin: vw(55) 0 vw(40);
				}

				.content {
					flex-direction: column-reverse;
					flex-wrap: wrap;
					.left{
						width: 100%;
					}

					.right {
						width: 100%;
						margin: 0;
						.search_area {
							width: 100%;
							height: vw(70);
							margin: vw(35) 0 vw(18);
							display: none;
							justify-content: space-between;
							align-items: center;

							.search_input {
								width: vw(600);
								height: vw(70);
								border: vw(5) solid #6b18a8;
								border-radius: vw(16);
								padding: 0 vw(40);
								display: flex;
								align-items: center;

								svg {
									width: vw(39);
									height: vw(39);
									margin-right: vw(12);
								}

								input {
									font-size: vw(24);
									line-height: vw(48);
									width: 100%;
									border: 0;
								}
							}

							.caret_icon {
								display: block;
								text-align: center;
								cursor: pointer;

								i,
								span {
									display: block;
								}

								i {
									color: #6B18A8;
								}

								span {
									font-size: vw(24);
									color: #738294;
								}
							}

						}

						.operate_list {
							display: flex;
							flex-wrap: wrap;
							justify-content: space-between;
							box-shadow: 0 vw(5) vw(10) rgba(0, 0, 0, .2);
							margin: vw(35) 0 0 0;

							li {
								width: vw(225);
								height: vw(120);
								padding: vw(20) 0 0 0;
								margin: 0 0 vw(2) 0;
								border-right: vw(1) solid #E4E4E4;
								background: #f6f8fa;
								text-align: center;
								cursor: pointer;

								p {
									font-size: vw(24);
									color: #545C69;
									margin-top: vw(13);
								}
								
								svg:first-of-type{
									display: inline;
								}
								svg:nth-of-type(2){
									display: none;
								}

								&:first-of-type {
									display: none;

									svg {
										width: vw(28);
										height: vw(26);
									}
								}

								&:nth-of-type(2) {
									border-radius: vw(8) 0 0 vw(8);

									svg {
										width: vw(30);
										height: vw(26);
									}
								}

								&:nth-of-type(3) {
									display: none;

									svg {
										width: vw(25);
										height: vw(29);
									}
								}

								&:nth-of-type(4) {
									display: none;

									svg {
										width: vw(26);
										height: vw(22);
									}
								}

								&:nth-of-type(5) {
									svg {
										width: vw(24);
										height: vw(28);
									}
								}

								&:nth-of-type(6) {
									border: 0;
									border-radius: 0 vw(8) vw(8) 0;

									svg {
										width: vw(26);
										height: vw(25);
									}
								}

								&:hover {
									background: #6B18A8;
									box-shadow: 0 vw(5) vw(10) rgba(0, 0, 0, .5);

									p {
										color: #fff;
									}

									svg {
										color: white;
									}
									svg:first-of-type{
										display: none;
									}
									svg:nth-of-type(2){
										display: inline;
									}
								}
								&.current{
									box-shadow: 0 vw(5) vw(10) rgba(0, 0, 0, .5);
									svg:first-of-type{
										display: none;
									}
									svg:nth-of-type(2){
										display: inline;
									}
								}
							}
						}
						.adver_area,.end_btn{
							display: none;
						}

					}
				}
			}
		}
	}
</style>
